import { checkHttpStatus } from "../../../common/utils/utils";
import ScanService from "../../../services/inbound/scan.service";
import * as _Constants from './constants';
import { apiService } from "../../../common/apiService/service";

const scanService = new ScanService

export const fetchConsigmentUnassigned = (payload, isReset) => dispatch => {
    dispatch(fetchConsigmnetsBegin());
    scanService.fetchConsigmentUnassigned(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchConsigmnetsSuccess(res?.data?.response || res?.data?.viewResponse, isReset));
        } else {
            dispatch(fetchConsigmnetsFailure(res?.data?.response));
        }
    });
};

export const createManifests = (payload) => {
    return apiService.post("hub/manifest", payload);
}

export const createRtoManifest = (payload) => {
    return apiService.post("hub/rto/manifest", payload);
}

export const linkWaybill = (payload) => {
    return apiService.put("hub/manifest/waybill", payload);
}

export const linkRTOWaybill = (payload) => {
    return apiService.post(`hub/rto/manifest/${payload.manifestCode}/waybill/${payload.waybillNo}`, payload);
}

export const deLinkWaybill = (payload) => {
    return apiService.delete("hub/manifest/" + payload.manifestCode + "/waybill/" + payload.waybillNo);
}

export const deLinkRTOWaybill = (payload) => {
    return apiService.delete(`hub/rto/manifest/${payload.manifestCode}/waybill/${payload.waybillNo}`);
}

export const deleteManifest = (manifestCode) => {
    return apiService.delete(`hub/manifest/${manifestCode}`);
}

export const fetchManifests = (payload) => {
    return apiService.post("hub/manifest/fetch", payload);
}

export const fetchManifestDetailsByCode = (manifestCode) => {
    return apiService.get(`hub/manifest/fetch/${manifestCode}`)
}

export const lockUnlockManifest = (payload) => {
    return apiService.put(`hub/manifest/lock-unlock/${payload.manifestCode}`, null,
        { queryParams: { shouldLock: payload.shouldLock } }
    )
}

export const fetchDestinationLoc = (value) => {
    return apiService.get(`hub/manifest/destination/location`,
        { queryParams: { locationName: value } }
    )
}

export const fetchRtoDestinationLoc = (value) => {
    return apiService.get(`hub/rto/manifest/destination/location`,
        { queryParams: { locationName: value } }
    )
}

export const fetchLinkedWaybill = (reqBody) => {
    return apiService.post('hub/manifest/linked/waybill', reqBody)
}

export const fetchPendingWaybillCount = (params) => {
    return apiService.get("hub/manifest/pending/waybill/count",
        { queryParams: params, showLoader: false })
}

export const fetchSingleManifest = (manifestCode) => {
    return apiService.get(`hub/manifest/fetch/${manifestCode}`)
}

export const fetchRtoSingleManifest = (manifestCode) => {
    return apiService.get(`hub/rto/manifest/fetch/${manifestCode}`)
}

export const changeManifestCode = (reqBody) => {
    return apiService.put(`hub/manifest/replace`, reqBody)
}

export const generateManifestCode = () => {
    return apiService.get(`hub/manifest/code`)
}

export const fetchRtoManifests = (payload) => {
    return apiService.post("hub/rto/manifest/fetch", payload);
}

export const fetchRtoPendingWaybillCount = (params) => {
    return apiService.get("hub/rto/manifest/pending/waybill/count",
        { queryParams: params, showLoader: false }
    )
}

function fetchConsigmnetsBegin() {
    return {
        type: _Constants.FETCH_CONSIGMENTS_BEGIN
    };
}

function fetchConsigmnetsSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_CONSIGMENTS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchConsigmnetsFailure(error) {
    return {
        type: _Constants.FETCH_CONSIGMENTS_FAILURE,
        payload: error
    };
}

export const getConsignment = (id) => dispatch => {
    dispatch(fetchConsigmnetBegin());
    scanService.getConsignment(id).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchConsigmnetSuccess(res?.data?.response));
        } else {
            dispatch(fetchConsigmnetFailure(res?.data?.response));
        }
    });
};

function fetchConsigmnetBegin() {
    return {
        type: _Constants.FETCH_CONSIGMENT_BEGIN
    };
}

function fetchConsigmnetSuccess(payload) {
    return {
        type: _Constants.FETCH_CONSIGMENT_SUCCESS,
        payload: payload,
    };
}

function fetchConsigmnetFailure(error) {
    return {
        type: _Constants.FETCH_CONSIGMENT_FAILURE,
        payload: error
    };
}

export const updateConsignment = (payload) => dispatch => {
    dispatch(updateConsigmnetBegin());
    scanService.consignmentUpdate(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(updateConsigmnetSuccess(res?.data?.response));
        } else {
            dispatch(updateConsigmnetFailure(res?.data?.response));
        }
    });
};

function updateConsigmnetBegin() {
    return {
        type: _Constants.UPDATE_CONSIGMENT_BEGIN
    };
}

function updateConsigmnetSuccess(payload) {
    return {
        type: _Constants.UPDATE_CONSIGMENT_SUCCESS,
        payload: payload,
    };
}

function updateConsigmnetFailure(error) {
    return {
        type: _Constants.UPDATE_CONSIGMENT_FAILURE,
        payload: error
    };


}