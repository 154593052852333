import React, { Component } from 'react';
import './audit-notice.scss';
import * as actions from '../action';
import { connect } from 'react-redux';
import { openNotification, destroyNotification } from '../../../ui/notification/Notification';
import { Constants } from '../../../../common/utils/constants';
import { Modal, Button } from 'antd';

class AuditNotice extends Component {

  state = {
    showModal: false,
    username: ''
  }

  componentDidMount() {
    this.props.getAuditStatus({ locationId: Constants.LOGGED_PARTNER_LOCATION_ID });
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auditStatus && nextProps.auditStatus.username !== this.props.auditStatus?.username) {
      this.setState({
        username: nextProps.auditStatus.username
      })
    }
    if (nextProps.auditStatus && nextProps.auditStatus.isAuditOn !== this.props.auditStatus?.isAuditOn) {
      if (nextProps.auditStatus.isAuditOn) {
        destroyNotification()
        openNotification({
          message: 'Information',
          description: 'Audit is still in progress',
          className: 'error-notification',
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userInfo && this.props.userInfo.loggedPartnerId !== prevProps.userInfo?.loggedPartnerId) {
      this.recheckAuditStatus();
    }
  }

  recheckAuditStatus = () => {
    this.props.history.push(`/appv2/inventory/form/audit/${this.props.auditStatus.auditInProgressID}`)
    //this.props.getAuditStatus({ locationId: Constants.LOGGED_PARTNER_LOCATION_ID });
  }


  render() {
    return ((this.props.auditStatus?.isAuditOn) && (!this.props.location?.pathname.includes("/appv2/inventory/form/audit"))) ? (
      <React.Fragment>
        <div className="Audit-Notice">
          <p>
          Audit in Progress. It is advised to close the Audit before doing any further operations. <span className="clickHere" onClick={this.toggleModal}>Click here</span> to know more
           </p>
          <div className="recheck">
            <Button type="primary"
              onClick={this.recheckAuditStatus}
              loading={this.props.loading}
            >Resume Audit</Button>
          </div>
        </div>
        <Modal
          wrapClassName="audit-modal"
          onCancel={this.toggleModal}
          onOk={this.toggleModal}
          visible={this.state.showModal}
          footer={null}>
          <div className="header">AUDIT IN PROGRESS</div>
          <p>An Audit is in progress in your location. Please scan all the shipment waybill numbers that are available in the hub in the audit page, and then proceed to finish the audit. It is advised to not inbound new shipments or dispatch existing shipments from the hub while the Audit is in progress.</p>
          <div className="footer">
            <Button
              className="btn"
              onClick={this.toggleModal}
            >Got It</Button>
          </div>
        </Modal>
      </React.Fragment>
    ) : ''
  }
}

const mapStateToProps = state => ({
  auditStatus: state.audit?.auditStatus,
  loading: state.audit?.auditStatus?.loading
})

const mapDispatchToProps = dispatch => ({
  getAuditStatus: (param) => dispatch(actions.getAuditStatus(param)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditNotice);
