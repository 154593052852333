import React, { useEffect, useState } from "react";
import * as drsClosureAction from "../action";
import { Button, Card, Select } from "antd";
import { capitalizeString, notifyApiError, notifyApiSuccess, underScoredToReadable } from "../../../../common/utils/utils";
import "../pod-drs-closure.styles.scss";
import { useSelector } from "react-redux";


const ValidateResyncCard = ({ toggleLoader, handleValidateRes,
    triggerCloseDrsModal, clearInput }) => {

    const config = useSelector(state => state?.app?.configurations)
    const [deliveryExecutives, setDeliveryExecutives] = useState([]);
    const [selectedFe, setSelectedFe] = useState({});

    useEffect(() => {
        toggleLoader(true)
        if (true) {
            drsClosureAction.fetchFeList()
                .then((res) => {
                    setDeliveryExecutives(res?.userDetails)
                    toggleLoader(false)
                })
                .catch(e => {
                    toggleLoader(false)
                    console.error("fetchFeList failed with following error -> " + e)
                })
        } else {
            drsClosureAction.fetchDeliveryExecutives()
                .then((data) => {
                    setDeliveryExecutives(data?.response?.users ? data.response.users : [])
                    toggleLoader(false)
                })
                .catch((error) => {
                    toggleLoader(false)
                    console.error("Error while calling fetchDeliveryExecutives api", error)
                })
        }
    }, [])

    useEffect(() => {
        setSelectedFe({})
    }, [clearInput])

    const triggerValidateFe = (selectedDeInfo, triggeredBy) => {

        const feId = selectedDeInfo && selectedDeInfo.feId ? selectedDeInfo.feId : selectedFe.feId

        drsClosureAction.validateFe(feId)
            .then((data) => {
                if (data?.length > 0) {
                    // Handling case when no open DRS is found for the FE.
                    if (data[0]?.waybillCount == 0 && data[0]?.message) {
                        notifyApiSuccess(data[0]?.message)
                        handleValidateRes({})
                    } else {
                        const formattedRes = formatValidateResponse(data)
                        const feDetails = {
                            "feId": feId,
                            "feName": selectedDeInfo && selectedDeInfo.feName ? selectedDeInfo.feName : selectedFe.feName,
                            "clientLevelCollectedCodAmountInfo" : formattedRes.clientLevelCollectedCodAmountInfo,
                            "collectedCodAmount": formattedRes.totalCollectedCodAmountInfo.dataCount
                        }
                        if (formattedRes?.undelShipment?.length > 0 || formattedRes?.ofdShipment?.length > 0) {
                            if (triggeredBy != "reSync") {
                                notifyApiError("Create POD for OFD shipments and In-scan undelivered shipments to close DRS")
                            }
                        } else {
                            // Calling callback function to open close drs modal
                            triggerCloseDrsModal(feDetails)
                        }

                        // Passing back the formatted validate FE response and FE details
                        handleValidateRes({ feDetails: feDetails, formattedRes: formattedRes })
                    }
                } else if (data?.message) {
                    notifyApiError(data.message)
                }
            })
            .catch((error) => {
                console.error("Error while calling validateRtoDrs api", error)
                notifyApiError("Operation Failed. Please contact support")
            })
    }

    const formatValidateResponse = (validateRes) => {
        let formattedValidateResData = {
            totalDelInfo: {
                dataCount: 0,
                displayText: "Delivered"
            },
            totalUndelInfo: {
                dataCount: 0,
                displayText: "Undelivered"
            },
            totalUnAttemptedInfo: {
                dataCount: 0,
                displayText: "Pending"
            },
            totalCollectedCodAmountInfo: {
                dataCount: 0,
                displayText: "COD Amount to be Collected"
            },
            clientLevelCollectedCodAmountInfo : {},
            undelShipment: [],
            ofdShipment: []
        }

        validateRes.map((drsData) => {

            // Calculating total count
            formattedValidateResData.totalDelInfo.dataCount += drsData.delCount
            formattedValidateResData.totalUndelInfo.dataCount += drsData.undelCount
            formattedValidateResData.totalCollectedCodAmountInfo.dataCount += isNaN(parseFloat(drsData.collectedCodAmount)) ? 0 : drsData.collectedCodAmount
            formattedValidateResData.totalUnAttemptedInfo.dataCount += drsData.unAttemptedCount

            // Segregating UNDEL and OFD shipments
            if (drsData?.consignments?.length > 0) {
                drsData.consignments.map((consignmentInfo) => {
                    if (consignmentInfo?.consignment?.podStatus == null) {
                        formattedValidateResData.ofdShipment.push({...consignmentInfo.consignment, drsCode : drsData.drsCode})
                    }

                    if (consignmentInfo?.consignment?.podStatus == "UNDEL" && !consignmentInfo?.consignment?.inScanned) {
                        formattedValidateResData.undelShipment.push({...consignmentInfo.consignment, drsCode : drsData.drsCode})
                    }
                })
            }

            if (drsData?.clientLevelCod?.length > 0) {
                drsData?.clientLevelCod.map(clientLevelCodInfo => {
                    if (clientLevelCodInfo.customer) {
                        var formattedClientLevelCoDInfo = {
                            codAmount : 0,
                            collectedAmount : 0,
                            delCount : 0,
                            customer : clientLevelCodInfo?.customer ? underScoredToReadable(clientLevelCodInfo?.customer) : "LSN"
                        };
                        if (formattedValidateResData.clientLevelCollectedCodAmountInfo[clientLevelCodInfo?.customer]) {
                            formattedClientLevelCoDInfo =
                             { ...formattedValidateResData.clientLevelCollectedCodAmountInfo[clientLevelCodInfo?.customer] };
                        }
                        formattedClientLevelCoDInfo.codAmount += clientLevelCodInfo.codAmount;
                        formattedClientLevelCoDInfo.collectedAmount += clientLevelCodInfo.collectedAmount;
                        formattedClientLevelCoDInfo.delCount += clientLevelCodInfo.delCount;
                        formattedValidateResData.clientLevelCollectedCodAmountInfo[clientLevelCodInfo?.customer] = formattedClientLevelCoDInfo;
                    }
                });
            }
        })

        return formattedValidateResData;

    }

    return (
        <>
            <Card className="validate-resync-card flex-box" >
                <Select placeholder="Select Delivery Executive"
                    showSearch={true} value={selectedFe.feId}
                    onChange={(value, option) => {
                        const selectedDeInfo = {
                            "feId": value,
                            "feName": option?.props?.children
                        }
                        setSelectedFe(selectedDeInfo)
                        triggerValidateFe(selectedDeInfo)
                    }}
                    className="fe-select-input"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {
                        deliveryExecutives?.length > 0 &&
                        deliveryExecutives.map((user, index) => (
                            <Select.Option value={user.id} key={user.id} style={{ borderBottom: index == deliveryExecutives.length - 1 ? "none" : "1px solid #F5F5F5" }}>
                                {user.name}
                            </Select.Option>
                        ))
                    }
                </Select>
                {selectedFe && selectedFe.feId &&
                    <>
                        <div>
                            <Button className="validate-resync-btn lsn-btn-primary" onClick={() => triggerValidateFe(null)}>Validate FE</Button>
                        </div>

                        <div>
                            <Button className="validate-resync-btn lsn-btn-primary" onClick={() => triggerValidateFe(null, "reSync")}>Re-sync Data</Button>
                        </div>
                    </>
                }
            </Card>

        </>
    )

}

export default ValidateResyncCard;