import * as CONSTANTS from './constants';
import { checkReachAuditEnabled, storePaginatedData } from '../../../common/utils/utils';

export const auditReducer = (state = {}, action) => {
  switch (action.type) {
    case CONSTANTS.AUDIT_CHECK_BEGIN:
      return {
        ...state,
        auditStatus: {
          loading: true,

        }
      }
    case CONSTANTS.AUDIT_CHECK_SUCCESS:
      const enabledRearchAudit = checkReachAuditEnabled()
      if (enabledRearchAudit) {
        return {
          ...state,
          auditStatus: {
            loading: false,
            isAuditOn:action.payload.auditOn,
            ...action.payload,
          },
          userName: action.payload.userName
        }
      } else {
        return {
          ...state,
          auditStatus: {
            loading: false,
            ...action.payload
          }
        }
      }
    case CONSTANTS.AUDIT_CHECK_FAILURE:
      return {
        ...state,
        auditStatus: {
          loading: false,
        }
      }
    case CONSTANTS.AUDIT_CHECK_FINISH:
        return {
          ...state,
          auditStatus: {
            loading: false
          }
      }
    case CONSTANTS.FETCH_AUDITS_BEGIN: return fetchUsersBegin(state);
    case CONSTANTS.FETCH_AUDITS_SUCCESS: return fetchUsersSuccess(state, action);
    case CONSTANTS.FETCH_AUDITS_FAILURE: return fetchUsersFailure(state, action);
    default: return state;
  }
}

function fetchUsersBegin(state, action) {
  return {
    ...state,
    loading: true

  };
}

function fetchUsersSuccess(state, action) {
  const enabledRearchAudit = checkReachAuditEnabled()
  if (enabledRearchAudit) {
    return {
      ...state,
      loading: false,
      PaginatedAudits: storePaginatedData(action?.payload?.pageNo, state.PaginatedAudits ? state.PaginatedAudits : {}, action?.payload?.auditInfoList, action.isReset),
      ...action.payload
    };
  } else {
    return {
      ...state,
      loading: false,
      PaginatedAudits: storePaginatedData(action?.payload?.currentPageNo, state.PaginatedAudits ? state.PaginatedAudits : {}, action?.payload?.auditInfos, action.isReset),
      ...action.payload
    };
  }
}

function fetchUsersFailure(state, action) {
  return {
    ...state,
    loading: false,
    error: action.payload,
    customers: []
  };
}


