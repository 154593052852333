export const ACTION_TYPES = {
    TOGGLE_LOADER     : "TOGGLE_LOADER",
    TOGGLE_LOADER_ON  : "TOGGLE_LOADER_ON",
    TOGGLE_LOADER_OFF : "TOGGLE_LOADER_OFF",
    TOGGLE_TAB_LOADER : "TOGGLE_TAB_LOADER",
    TOGGLE_TAB_LOADER_ON : "TOGGLE_TAB_LOADER_ON",
    TOGGLE_TAB_LOADER_OFF : "TOGGLE_TAB_LOADER_OFF"
}

export const LoaderReducer = (state = { loader : false, tabLoader: false } , action = {}) => {

    switch (action.type) {
        case ACTION_TYPES.TOGGLE_LOADER     : return {
            ...state,
            loader : !state.loader
        }
        case ACTION_TYPES.TOGGLE_LOADER_ON  : return {
            ...state,
            loader : true
        }
        case ACTION_TYPES.TOGGLE_LOADER_OFF : return {
            ...state,
            loader : false
        }
        case ACTION_TYPES.TOGGLE_TAB_LOADER : return {
            ...state,
            tabLoader : !state.tabLoader
        }
        case ACTION_TYPES.TOGGLE_TAB_LOADER_ON : return {
            ...state,
            tabLoader : true
        }
        case ACTION_TYPES.TOGGLE_TAB_LOADER_OFF : return {
            ...state,
            tabLoader : false
        }
        default : return state;
    }
}