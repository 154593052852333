import { Constants } from "../common/utils/constants";
import * as utils from "../common/utils/utils";
import { pModules, permissionTags } from "./permissionTags";

// path : path of menu/tab to be appended in url
// label: string to be displayed on UI for a menu/tab
// name : key for a menu/tab present in permissions object
export const menuconfig = [
  {
    key: utils.generateID(),
    path: "sc-ops",
    label: "Dashboard",
    name: pModules.sc_operation.title,
    inactiveIcon: "trip_inactive.svg",
    activeIcon: "trip_active.svg",
    canAllow: true,
    type: "link",
    children: [
      {
        label: "Overview",
        path: "sc-overview",
        name: "Overview",
        canAllow: true
      },
      {
        label: "Daily Actions",
        path: "sc-daily-action",
        name: "SC Daily Action",
        canAllow: true
      },
      {
        label: "RTO Daily Actions",
        path: "sc-rto-daily-action",
        name: "SC RTO Daily Action",
        canAllow: ["5879892", "5880176", "5880968"].includes(Constants.userLocationId + "")
      },
      {
        label: "IPP Dashboard",
        path: "ipp-dashboard",
        name: "IPP Dashboard",
        canAllow: true
      },
      {
        label: "User Scan Count",
        path: "user-scan-count",
        name: "User Scan Count",
        canAllow: true
      },
      {
        label: "Shipment Discrepancy",
        path: "shipment-discrepancy",
        name: "Shipment Discrepancy",
        canAllow: true
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "fm-ops",
    label: "Dashboard",
    name: pModules.fm_operation.title,
    inactiveIcon: "4_b.svg",
    activeIcon: "4_w.svg",
    canAllow: true,
    type: "link",
    children: [
      {
        label: "Forward",
        path: "forward",
        name: "Forward",
        canAllow: true
      },
      {
        label: "RTO",
        path: "rto",
        name: "rto",
        canAllow: true
      },
      {
        label: "FE Onboarding Status",
        path: "fe-onboarding-status",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: Constants?.PARTNER_ID == 268
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "leads",
    label: "Pickup",
    name: pModules.leads.title,
    inactiveIcon: "1_b.svg",
    activeIcon: "1_w.svg",
    canAllow: utils.canAllow(permissionTags.leads.module),
    type: "link",
    children: [
      {
        label: "Pickup Report",
        path: "pickup-report",
        name: pModules.leads.subModule.lead,
        canAllow: utils.canAllow(permissionTags.leads.managePickup.subModule)
      },
      {
        label: "Manage Pickup",
        path: "manage-pickup",
        name: pModules.leads.subModule.lead,
        canAllow: utils.canAllow(permissionTags.leads.managePickup.subModule)
      },
      {
        label: "Upload Assigned Pickup",
        path: "upload-pickup",
        name: pModules.leads.subModule.lead,
        canAllow: utils.canAllow(permissionTags.leads.assignPickup.subModule)
      },
      {
        label: "Manage Reverse Pickup",
        path: "manage-reverse-pickup",
        name: pModules.leads.subModule.lead,
        canAllow: utils.canAllow(permissionTags.leads.manageReversePickup.subModule)
      },
      {
        label: "RVP Image Audit",
        path: "reverse-image-audit",
        name: pModules.leads.subModule.lead,
        canAllow: utils.canAllow(permissionTags.leads.manageReversePickup.subModule)
      },
      {
        label: "Reverse Pickup Report",
        path: "reverse-pickup-report",
        name: pModules.leads.subModule.lead,
        canAllow: utils.canAllow(permissionTags.leads.manageReversePickup.subModule)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "inbound",
    label: "Inbound",
    name: pModules.inboud.title,
    inactiveIcon: "2_b.svg",
    activeIcon: "2_w.svg",
    canAllow: utils.canAllow(permissionTags.inbound.module),
    type: "link",
    children: [
      {
        label: "Inbound",
        path: "scan",
        name: pModules.inboudView.subModule.scan,
        canAllow: utils.canAllow(permissionTags.inbound.scan.subModule),
      },
      {
        label: "Expected",
        path: "expected",
        name: pModules.inboudView.subModule.scan,
        canAllow: utils.canAllow(permissionTags.inbound.scan.read),
      },
      {
        label: "RTO Expected",
        path: "rto-expected",
        name: pModules.inboudView.subModule.scan,
        canAllow: utils.canAllow(permissionTags.inbound.scan.read),
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "inventory",
    label: "Inventory",
    name: pModules.inventory.title,
    inactiveIcon: "3_b.svg",
    activeIcon: "3_w.svg",
    canAllow: utils.canAllow(permissionTags.inventory.module),
    type: "link",
    children: [
      {
        label: "Waybill",
        path: "awb",
        name: pModules.inventory.subModule.awb,
        canAllow: utils.canAllow(permissionTags.inventory.waybill.subModule),
      },
      {
        label: "DRS",
        path: "drs",
        name: pModules.inventory.subModule.drs,
        canAllow: utils.canAllow(permissionTags.inventory.drs.subModule)
      },
      {
        label: "Exceptions",
        path: "exceptions",
        name: pModules.inventory.subModule.exceptions,
        canAllow: utils.canAllow(permissionTags.inventory.exceptions.subModule),
        isReact: true
      },
      {
        label: "Manifest",
        path: "manifest-partner",
        name: pModules.inventory.subModule.manifest,
        canAllow: utils.canAllow(permissionTags.inventory.manifest_partner.subModule)
      },
      {
        label: "Audit",
        path: "audit",
        name: pModules.audits.subModule.audit,
        canAllow: utils.canAllow(permissionTags.audits.audit.subModule)
      },
      {
        label: "Audit Report",
        path: "audit-report",
        name: pModules.audits.subModule.audit,
        canAllow: (utils.canAllow(permissionTags.audits.audit.subModule) && Constants.PARTNER_ID === 268)
      },
      {
        label: "Closure",
        path: "manifest-closure",
        name: pModules.inventory.subModule.closure,
        canAllow: utils.canAllow(permissionTags.inventory.closure.subModule)
      },
      {
        label: "Label Printing",
        path: "label-printing",
        name: pModules.inventory.subModule.label_printing,
        canAllow: utils.canAllow(permissionTags.inventory.label_printing.subModule),
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "tracking",
    label: "Tracking",
    name: pModules.tracking.title,
    inactiveIcon: "4_b.svg",
    activeIcon: "4_w.svg",
    canAllow: utils.canAllow(permissionTags.tracking.module),
    type: "link",
    children: [
      {
        label: "Tracking",
        path: "tracking",
        name: pModules.tracking.subModule.tracking,
        canAllow: utils.canAllow(permissionTags.tracking.tracking.subModule)
      },
      {
        label: "Call Masking Dashboard",
        path: "call-masking-adoption",
        name: pModules.tracking.subModule.tracking,
        canAllow: utils.canAllow(permissionTags.tracking.tracking.subModule)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "trips",
    label: "Trips",
    name: pModules.trips.title,
    inactiveIcon: "trip_inactive.svg",
    activeIcon: "trip_active.svg",
    canAllow: utils.canAllow(permissionTags.trips.module),
    type: "link",
    children: [
      {
        label: "Connection Sheets",
        path: "connection-sheet",
        name: pModules.trips.subModule.connection_sheet,
        canAllow: utils.canAllow(permissionTags.trips.connection_sheet.subModule)
      },
      {
        label: "Inbound Trips",
        path: "inbound",
        name: pModules.trips.subModule.inbound,
        canAllow: utils.canAllow(permissionTags.trips.inbound.subModule)
      },
      {
        label: "Outbound Trips",
        path: "outbound",
        name: pModules.trips.subModule.outbound,
        canAllow: utils.canAllow(permissionTags.trips.outbound.read)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "pod",
    label: "POD",
    name: pModules.pod.title,
    inactiveIcon: "5_b.svg",
    activeIcon: "5_w.svg",
    canAllow: utils.canAllow(permissionTags.pod.module),
    type: "link",
    children: [
      {
        label: "Live",
        path: "drs",
        name: pModules.pod.subModule.drs,
        canAllow: utils.canAllow(permissionTags.pod.drs.subModule),
      },
      {
        label: "DRS Closure",
        path: "drs-closure",
        name: pModules.pod.subModule.drs_closure,
        canAllow: utils.canAllow(permissionTags.pod.drs_closure.subModule)
      },
      {
        label: "POD Audit",
        path: "pod-audit",
        name: pModules.pod.subModule.drs_closure,
        canAllow: utils.canAllow(permissionTags.pod.drs_closure.subModule)
      },
      {
        label: "Past Suspicious PODs",
        path: "pending-suspicious-pods",
        name: pModules.pod.subModule.drs_closure,
        canAllow: utils.canAllow(permissionTags.pod.drs_closure.subModule)
      },
      {
        label: "POD Audit Validation",
        path: "pod-audit-validation",
        name: pModules.pod.subModule.drs_closure,
        canAllow: utils.canAllow(permissionTags.pod.drs_closure.subModule)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "cod",
    label: "COD",
    name: pModules.cod_new.title,
    inactiveIcon: "6_b.svg",
    activeIcon: "6_w.svg",
    canAllow: utils.canAllow(permissionTags.cod_new.module),
    type: "link",
    children: [
      {
        label: "COD",
        path: "cod",
        name: pModules.cod_new.title,
        canAllow: utils.canAllow([permissionTags.cod_new.collect.subModule, permissionTags.cod_new.remit.subModule])
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "settings",
    label: "Settings",
    name: pModules.settings.title,
    inactiveIcon: "7_b.svg",
    activeIcon: "7_w.svg",
    canAllow: utils.canAllow(permissionTags.settings.module),
    type: "link",
    children: [
      {
        label: "Customer",
        path: "customer",
        name: pModules.settings.subModule.customer,
        canAllow: utils.canAllow(permissionTags.settings.customer.subModule)
      },
      {
        label: "User",
        path: "user",
        name: pModules.settings.subModule.user,
        canAllow: utils.canAllow(permissionTags.settings.user.subModule)
      },
      {
        label: "Route",
        path: "route",
        name: pModules.settings.subModule.route,
        canAllow: utils.canAllow(permissionTags.settings.route.subModule)
      },
      {
        label: "Location",
        path: "location",
        name: pModules.settings.subModule.location,
        canAllow: utils.canAllow(permissionTags.settings.location.subModule)
      },
      {
        label: "Partners Onboarding Legacy",
        path: "partners-onboarding",
        name: pModules.settings.subModule.partners_onboarding,
        canAllow: utils.canAllow(
          permissionTags.settings.partners_onboarding.subModule,
          true
        )
      },
      {
        label: "Zoho",
        path: "zoho-dashboard",
        name: pModules.settings.subModule.zoho,
        canAllow: utils.canAllow(permissionTags.settings.zoho.subModule)
      },
      {
        label: "Custom Config",
        path: "custom-config",
        name: pModules.settings.subModule.custom_config,
        canAllow: utils.canAllow(
          permissionTags.settings.custom_config.subModule,
          true
        )
      },
      {
        label: "Network Visibility",
        path: "network-visibility",
        name: "network-visibility",
        canAllow: utils.canAllow(permissionTags.settings.location.subModule)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "rto",
    label: "RTO",
    name: pModules.rto.title,
    inactiveIcon: "8_b.svg",
    activeIcon: "8_w.svg",
    canAllow: utils.canAllow(permissionTags.rto.module),
    type: "link",
    children: [
      {
        label: "Waybill",
        path: "waybill",
        name: pModules.rto.subModule.waybill,
        canAllow: utils.canAllow(permissionTags.rto.waybill.subModule)
      },
      {
        label: "RTO DRS",
        path: "drs",
        name: pModules.rto.subModule.drs,
        canAllow: utils.canAllow(permissionTags.rto.drs.subModule),
      },
      {
        label: "RTO Manifest",
        path: "manifest-partner",
        name: pModules.rto.subModule.manifest,
        canAllow: utils.canAllow(permissionTags.rto.manifest_partner.subModule)
      },
      {
        label: "RTO Eligible",
        path: "rto-eligible",
        name: pModules.rto.subModule.rto_eligible,
        canAllow: utils.canAllow(permissionTags.rto.rto_eligible.subModule)
      },
      {
        label: "RTO Upload",
        path: "upload-rto",
        name: pModules.rto.subModule.rto_bulk_upload,
        canAllow: utils.canAllow(permissionTags.rto.rto_eligible.subModule) && utils.checkConfigurationsForBooleanPermission('enabledRearchRTOBulkUpload')
      },
      {
        label: "DRS Closure",
        path: "drs-closure",
        name: pModules.rto.subModule.drs_closure,
        canAllow: utils.canAllow(permissionTags.rto.drs_closure.subModule)
      },
      {
        label: "RTO DRS Report",
        path: "rtodrs-report",
        name: pModules.rto.subModule.drs,
        canAllow: utils.canAllow(permissionTags.rto.drs.subModule)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "reports",
    label: "Reports",
    name: pModules.reports.title,
    inactiveIcon: "10_b.svg",
    activeIcon: "10_w.svg",
    canAllow: utils.canAllow(permissionTags.reports.module),
    type: "link",
    children: [
      {
        label: "Reports",
        path: "download",
        name: pModules.reports.subModule.reports,
        canAllow: utils.canAllow(permissionTags.reports.reports.subModule)
      },
      {
        label: 'Ingest',
        path: 'ingest',
        name: pModules.reports.subModule.ingest,
        canAllow: utils.canAllow(permissionTags.reports.ingest.subModule)
      },
      {
        label: 'Utility Report',
        path: 'utility-report',
        name: pModules.reports.subModule.utility,
        canAllow: utils.canAllow(permissionTags.reports.utility.subModule)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "digital-payment",
    label: "Digital Payment",
    name: pModules.digital_payment.title,
    inactiveIcon: "eway_inactive.svg",
    activeIcon: "eway_active.svg",
    canAllow: utils.canAllow(permissionTags.digital_payment.module),
    type: "link",
    children: [
      {
        label: "Payment Status",
        path: "payment-status",
        name: pModules.digital_payment.title,
        canAllow: utils.canAllow(permissionTags.digital_payment.payment_status.subModule)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "monitoring-reports",
    label: "Monitoring Reports",
    name: pModules.monitoring_reports.title,
    inactiveIcon: "10_b.svg",
    activeIcon: "10_w.svg",
    canAllow: utils.canAllow(permissionTags.monitoring_reports.module),
    type: "link",
    children: [
      {
        label: "First Mile",
        path: "first-mile?date_params=start_date,end_date",
        name: pModules.monitoring_reports.title,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.first_mile.subModule)
      },
      {
        label: "Last Mile",
        path: "last-mile",
        name: pModules.monitoring_reports.subModule.last_mile,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.last_mile.subModule)
      },
      {
        label: "Mid Mile",
        path: "mid-mile?date_params=start_date,end_date",
        name: pModules.monitoring_reports.subModule.mid_mile,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.mid_mile.subModule)
      },
      {
        label: "First Mile Admin",
        path: "first-mile-admin?date_params=start_date,end_date",
        name: pModules.monitoring_reports.subModule.first_mile_admin,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.first_mile_admin.subModule)
      },
      {
        label: "Performance Overview",
        path: "performance-overview",
        name: pModules.monitoring_reports.subModule.performance_overview,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.performance_overview.subModule)
      },
      {
        label: "Admin Monitoring",
        path: "admin-monitoring?date_params=start_date,end_date",
        name: pModules.monitoring_reports.subModule.admin_monitoring,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.admin_monitoring.subModule)
      },
      {
        label: "LM Hub Visibilty",
        path: "lm-hub-visibilty",
        name: pModules.monitoring_reports.subModule.lm_hub_visibilty,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.lm_hub_visibilty.subModule)
      },
      {
        label: "SC Hub Visibilty",
        path: "sc-hub-visibilty?date_params=start_date,end_date",
        name: pModules.monitoring_reports.subModule.sc_hub_visibilty,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.sc_hub_visibilty.subModule)
      },
      {
        label: "SC Visibilty",
        path: "sort-center-visibility",
        name: pModules.monitoring_reports.subModule.sc_visibility,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.sc_visibility.subModule)
      },
      {
        label: "FM Hub Visibilty",
        path: "fm-hub-visibility?date_params=start_date,end_date",
        name: pModules.monitoring_reports.subModule.fm_hub_visibility,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.fm_hub_visibility.subModule)
      },
      {
        label: "Embed View",
        path: "embed-view",
        name: pModules.monitoring_reports.subModule.embedded_view,
        canAllow: utils.canAllow(permissionTags.monitoring_reports.embedded_view.subModule)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "lm-ops",
    label: "LM Performance",
    name: pModules.lm_operation.title,
    inactiveIcon: "trip_inactive.svg",
    activeIcon: "trip_active.svg",
    canAllow: utils.canAllow(permissionTags.lm_operation.module),
    type: "link",
    children: [
      {
        label: "Pendency",
        path: "pendency",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read) && Constants?.PARTNER_ID == 268
      },
      {
        label: "Performance",
        path: "performance",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read) && Constants?.PARTNER_ID == 268
      },
      {
        label: "RVP Performance",
        path: "rvp-performance",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read) && Constants?.PARTNER_ID == 268
      },
      {
        label: "FE Onboarding Status",
        path: "fe-onboarding-status",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read) && Constants?.PARTNER_ID == 268
      },
      {
        label: "DAF",
        path: "daily-action",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read) && (['268', '127808', '269'].indexOf(Constants?.PARTNER_ID + "") > -1)
      },
      {
        label: "RVP DAF",
        path: "rvp-daily-action",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read) && Constants?.PARTNER_ID == 268
      },
      {
        label: "RVP Operations",
        path: "rvp-operation",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read) && Constants?.PARTNER_ID == 268
      },
      {
        label: "Live DRS",
        path: "live-drs",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read)
      },
      {
        label: "Live DRS Audit",
        path: "live-drs-audit",
        name: pModules.pod.subModule.drs_closure,
        canAllow: utils.canAllow(permissionTags.pod.drs_closure.subModule)
      },
      {
        label: "LM Health",
        path: "lm-health",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read)
      },
      {
        label: "RVP PRS",
        path: "rvp-prs",
        name: "RVP",
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read)
      },
      {
        label: "FE Performance",
        path: "fe-performance",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: Constants?.PARTNER_ID == 127808
      },
      {
        label: "RVP Health",
        path: "rvp-overview",
        name: pModules.lm_operation.subModule.live_drs,
        canAllow: utils.canAllow(permissionTags.lm_operation.live_drs.read)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "billing",
    label: "Billing",
    name: pModules.billing.title,
    inactiveIcon: "6_b.svg",
    activeIcon: "6_w.svg",
    canAllow: utils.canAllow(permissionTags.billing.module),
    type: "link",
    children: [
      {
        label: localStorage.user && JSON.parse(atob(localStorage.user)).location.locationOpsType != "SC" ? "Forward" : "Billing",
        path: "forward?date_params=start_date,end_date",
        name: "Forward",
        canAllow: true
      },
      {
        label: "RTO",
        path: "rto?date_params=start_date,end_date",
        name: "RTO",
        canAllow: localStorage?.user && JSON.parse(atob(localStorage.user)).location.locationOpsType != "SC"
      },
      {
        label: "RVP Rider Wise",
        path: "rvp-rider-wise?date_params=start_date,end_date",
        name: "RVP Rider Wise",
        canAllow: localStorage?.user && JSON.parse(atob(localStorage.user)).location.locationOpsType == "LM"
      }
    ]
  }
];