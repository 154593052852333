import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { fetchprintLabelData } from "../../leads/manage-reverse-pickup/action.js";
import LabelTemplate from "./label-template";
import EcomLabelTemplate from "./ecom-label-template.js";
import { Modal } from "antd";

const LabelRvp = ({ waybillNo, toPrint, handlePrintTask, refreshList, printData }) => {
    let componentRef = useRef(null);
    const pageStyle = waybillNo && isNaN(waybillNo) ? `@media print {
        @page {
            size    : 3in 4in; 
            margin  : 10px; 
            padding : 0;
        }
    }` : `@media print {
        @page {
            size    : 7.5in 6in; 
            margin  : 10px; 
            padding : 0;
        }
    }`;

    const [data, setData] = useState(null);

    useEffect(() => {
        if (data) {
            handlePrint()
        }
    }, [data])

    useEffect(() => {
        if (printData && Object.keys(printData).length > 0) {
            setData(printData)
        }
    }, [printData])

    useEffect(() => {

        if (waybillNo && waybillNo.length > 0) {
            fetchprintLabelData(waybillNo)
                .then((response) => {
                    setData(response)
                    refreshList();
                })
                .catch((e) => {
                    console.error("fetchprintLabelData failed with following error", e)
                    handlePrintTask()
                });
        }

    }, [toPrint])

    const handleAfterPrint = () => {
        handlePrintTask()
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: pageStyle,
        documentTitle: "Label",
        removeAfterPrint: true,
        onAfterPrint: handleAfterPrint
    });

    return (
        <div>
            <div style={{ display: "none" }} className="m-20">
                {
                    data ?
                        data?.customerId == 22404 ?
                        <EcomLabelTemplate data={data} flag={true} ref={componentRef} /> :
                        <LabelTemplate data={data} flag={true} ref={componentRef} /> :
                    <></>
                }
            </div>
        </div>
    )
}

export default LabelRvp;