import { Button, Divider, Input, Modal } from "antd";
import React, { useState } from "react";
import "../pod-drs-closure.styles.scss";
import '../../../../assets/styles/common.scss';
import * as drsClosureAction from "../action";
import { notifyApiError, notifyApiSuccess } from "../../../../common/utils/utils";
import { useSelector } from "react-redux";


const CloseDrsModal = ({ open, handleModalClose, handleModalConfirm, modalData,
    toggleLoader }) => {

    const [loader, setLoader] = useState(false);

    const [denominationsInput, setDenominationsInput] = useState({});
    const config = useSelector(state => state?.app?.configurations);
    const { getDenominationsInput, mandateDenominations } = config;

    const handleCancel = () => {
        handleModalClose()
    }

    const denominationsArrayMap = [
        [ "500" , "200", "100"],
        [ "50" , "20", "10"],
        [ "5" , "2", "1"]
    ]

    const handleConfirm = () => {

        if (getDenominationsInput) {

            if (Object.keys(denominationsInput).length > 0) {
                Object.keys(denominationsInput)?.map(denomination => {
                    if(isNaN(denominationsInput[denomination])) {
                        return notifyApiError(`Enter a valid input for ₹${denomination} denomination!`);
                    }
                });
            }

            if (mandateDenominations) {
                if (Object.keys(denominationsInput).length == 0) {
                    return notifyApiError(`Enter denominations to validate FE!`);
                }
                else {
                    var totalAmount = 0;
                    Object.keys(denominationsInput).map(denomination => {
                        totalAmount += denominationsInput[denomination]  ? Number(denominationsInput[denomination]) * denomination : 0;
                    })
                    console.log(totalAmount);
                    
                    if (totalAmount != modalData.collectedCodAmount) {
                        return notifyApiError(`Collect the right amout from the Rider!`);
                    }
                }
            }
        }

        toggleLoader(true)
        setLoader(true)

        const reqBody = {
            "cashCollected": modalData.collectedCodAmount.toString()
        }

        drsClosureAction.closeDrs(modalData.feId, reqBody)
            .then((data) => {
                setLoader(false)
                notifyApiSuccess(data)
                handleModalConfirm()
            })
            .catch((error) => {
                setLoader(false)
                notifyApiError(error?.errors)
                console.error("closeDrs api failed with following error", error)
            })

        toggleLoader(false)

    }

    return (
        <>
            <Modal
                visible={open}
                footer={[
                    <Button onClick={handleCancel}>Cancel</Button>,
                    <Button onClick={handleConfirm} loading={loader} className="lsn-btn-primary">
                        Close DRS
                    </Button>
                ]}
                closable={false}
                className="close-drs-modal"
            >
                <div><b>NOTE:</b> Please confirm the amount that you have collected
                    from {modalData.feName}</div>

                <ul className="drs-list-data">
                    <li>All the returned Waybills will move to Inventory Waybills.</li>
                    <li>POD will no longer be allowed against this DRS.</li>
                </ul>

                {
                    (modalData?.clientLevelCollectedCodAmountInfo && 
                    Object.keys(modalData?.clientLevelCollectedCodAmountInfo).length > 1) ?
                    <div className="flex-column flex-gap-l">
                        {
                            Object.keys(modalData?.clientLevelCollectedCodAmountInfo).map(customer => (
                                <div className="flex-box flex-gap-l align-items-center">
                                    <div className="flex-1"> 
                                        Cash Collected for <strong>{modalData?.clientLevelCollectedCodAmountInfo[customer].customer}</strong>:
                                    </div>
                                    <Input value={"₹ " + (modalData?.clientLevelCollectedCodAmountInfo[customer].collectedAmount || "0")} disabled={true} className="flex-1" />
                                </div>
                            ))
                        }
                        <Divider style={{ margin : 0 }}/>
                        <div className="flex-box flex-gap-l align-items-center">
                            <div className="flex-1"> Total Cash Collected: </div>
                            <Input value={"₹ " + (modalData.collectedCodAmount || "0")} disabled={true} className="flex-1" />
                        </div>
                    </div> :
                    <div className="flex-box flex-gap-l align-items-center">
                        <div>Collected Cash:</div>
                        <Input value={"₹ " + (modalData.collectedCodAmount ? modalData.collectedCodAmount.toString() : "0")}
                            disabled={true} className="flex-1" />
                    </div>
                }
                
                {/* Cash Collected Denominations */}
                {
                    getDenominationsInput &&
                    <div className="flex-column flex-gap-l" style={{ marginTop : 16 }}>
                        <u> <strong> Denominations </strong> </u>
                        {
                            denominationsArrayMap.map(denominationsArray => (
                                <div className="flex-box flex-gap-s justify-content-space-evenly">
                                    {
                                        denominationsArray.map(denomination => (
                                            <div className="flex-column flex-gap-s">
                                                ₹{denomination}
                                                <Input placeholder={`Number of ₹${denomination}s`}
                                                  type="number"
                                                  onChange={event => {
                                                    var val = event?.target?.value?.trim();
                                                    setDenominationsInput(denInp => {
                                                        denInp[denomination] = val;
                                                        return denInp;
                                                    })
                                                  }}/>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                }
            </Modal>
        </>
    )

}

export default CloseDrsModal;