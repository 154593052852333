import { Alert, Table, Tag } from "antd";
import TripsMenu from "./trips.menu";
import { useEffect, useState } from "react";
import {
    fetchInboundRoutes, fetchOutboundRoutes, fetchShipmentCountByTrips,
    fetchTrips, downloadConsignmentsList, fetchTripsV2, fetchOutboundRoutesV2
} from "./trips.service";
import moment from "moment";
import { capitalizeString, notifyApiError } from "../../../common/utils/utils";
import TripActionColumn from "./actions/trip.action.column";
import TripStatusModal from "../outbound-trips/trip.status.modal";
import { useSelector } from "react-redux";
import LoaderService from "../../../services/loader";

export default function TripsView({ tripFetchType, columns }) {

    const defaultFilterPayload = {
        tripCode: "",
        routeIds: [],
        pageNo: 1,
        pageSize: 10,
        startDate: moment().subtract(7, "days").startOf().valueOf(),
        endDate: moment().startOf().valueOf(),
        tripStatuses: [],
        tripFetchType: tripFetchType || "INBOUND_TRIP",
        originLocationIds: []
    }

    const [loading, SetLoading] = useState(true);
    const [routeOptions, SetRouteOptions] = useState([]);
    const [tripsResponse, SetTripsResponse] = useState(undefined);
    const [tripSheetPrint, SetTripSheetPrint] = useState([]);
    const [fetchTripsPayload, SetFetchTripsPayload] = useState({ ...defaultFilterPayload });
    const [selectedTripCode, SetSelectedTripCode] = useState(false);

    const locationOpsType = localStorage?.user ? JSON.parse(atob(localStorage.user))?.location?.locationOpsType : undefined;
    const partnerId = localStorage?.user ? JSON.parse(atob(localStorage.user))?.partner?.id : undefined;
    const config = useSelector(state => state?.app?.configurations);

    const commonColumns = [
        {
            title: "Trip No.",
            dataIndex: "tripCode",
            key: "tripId",
            width: 120,
            render: tripCode => {
                if (tripFetchType === "OUTBOUND_TRIP" && (locationOpsType == "SC" || partnerId == 268)) {
                    return <div className="clickable-link text-semi-bold" onClick={_ => SetSelectedTripCode(tripCode)}> {tripCode} </div>
                }
                return <div>{tripCode}</div>
            }
        },
        {
            title: "Vehicle Number",
            dataIndex: "vehicleNumber",
            key: "vehicleNumber",
            render: vehicleNumber => vehicleNumber ? vehicleNumber.toLocaleUpperCase() : <>--</>
        },
        {
            title: "Route",
            dataIndex: "routeName",
            key: "routeName"
        },
        ...(tripFetchType == "INBOUND_TRIP" ? [
            {
                title: "Origin",
                dataIndex: "originLocation.name",
                key: "originLocation.id"
            }] : []),
        {
            title: "Trip Status",
            dataIndex: "tripStatus",
            key: "tripStatus",
            render: status => {
                let tagClassName = "info-tag";
                switch (status) {
                    case "COMPLETED": tagClassName = "success-tag"; break;
                    case "PENDING": tagClassName = "error-tag"; break;
                    case "ARRIVED": tagClassName = "warning-tag"; break;
                    case "LOADED": tagClassName = "warning-tag"; break;
                    case "DISPATCHED": tagClassName = "info-tag"; break;
                    default: return "";
                }
                return (
                    <Tag className={"ant-table-tag text-align-center " + tagClassName}>
                        {
                            (status.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")
                        }
                    </Tag>
                )
            }
        }
    ];


    const onPaginationChange = pageNo => {
        SetFetchTripsPayload(current => {
            return {
                ...current,
                pageNo
            }
        });
    }

    const callFetchTrips = payload => {

        LoaderService.toggleTabLoaderOn()
        SetLoading(true);

        if (config?.enableRearch?.outboundTripReadOps === 'true' && tripFetchType === "OUTBOUND_TRIP") {

            let reqPayload = {}

            if (!payload.tripCode && payload.tripCode !== "") {
                reqPayload = {
                    filters: {
                        tripCode: payload.tripCode
                    }
                }
            } else {

                reqPayload = {
                    filters: {
                        startDate: payload.startDate,
                        endDate: payload.endDate
                    },
                    pageNo: payload.pageNo,
                    pageSize: payload.pageSize
                }

                reqPayload['filters']['tripStatus'] = payload.tripStatuses?.length > 0 ? payload.tripStatuses : []

                if (payload.routeIds?.length > 0) {
                    reqPayload['filters']['routeId'] = payload.routeIds[0]
                }
            }

            fetchTripsV2(reqPayload)
                .then(response => {

                    const formattedTripsRes = {
                        totalCount: response.totalCount,
                        currentPageNo: payload.pageNo,
                        maxPage: response.maxPage,
                        currentPageSize: payload.pageSize
                    }

                    const tripData = []

                    response.tripData.map(tripInfo => {
                        tripData.push(
                            {
                                tripId: tripInfo.tripId,
                                tripCode: tripInfo.tripCode,
                                routeName: tripInfo.route,
                                tripCreatedTime: parseInt(tripInfo.createdAt),
                                tripDispatchTime: parseInt(tripInfo.dispatchAt),
                                vehicleNumber: tripInfo.vehicleNumber,
                                tripStatus: tripInfo.tripStatus,
                                connectionCount: tripInfo.entityCountDetails?.connectionCount,
                                manifestCount: tripInfo.entityCountDetails?.manifestCount,
                                shipmentCount: tripInfo.entityCountDetails?.shipmentCount,
                                action: tripInfo.action
                            }
                        )
                    })

                    formattedTripsRes['trips'] = tripData

                    SetTripsResponse(formattedTripsRes)
                    SetLoading(false);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })

                    LoaderService.toggleTabLoaderOff()
                })
                .catch(e => {
                    console.log(e)
                    SetLoading(false);
                    LoaderService.toggleTabLoaderOff()
                })

        } else {
            fetchTrips(payload)
                .then(response => {
                    SetTripsResponse(response);
                    SetLoading(false);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                    if (response?.trips && response?.trips?.length > 0) {
                        LoaderService.toggleTabLoaderOn()
                        fetchShipmentCountByTrips({
                            tripIds: response?.trips?.map(t => t.tripId),
                            tripFetchType
                        })
                            .then(shipmentCountResponse => {
                                SetTripsResponse(current => {
                                    var tempTrips = { ...current };
                                    tempTrips.trips = tempTrips.trips.map(trip => {
                                        trip.shipmentCount = shipmentCountResponse?.trips?.find(t => t.tripId == trip.tripId)?.shipmentCount;
                                        return trip;
                                    })
                                    return tempTrips;
                                })
                                LoaderService.toggleTabLoaderOff()
                            })
                            .catch(e => {
                                console.log(e)
                                LoaderService.toggleTabLoaderOff()
                            })
                    } else {
                        LoaderService.toggleTabLoaderOff()
                    }
                })
                .catch(e => {
                    console.log(e)
                    LoaderService.toggleTabLoaderOff()
                })
        }
    };

    const handleMenuClick = (key, trip) => {
        if (key === "tripSheet") {
            window.open(`/appv2/print_information?tripId=${trip.tripId}&formtype=tripSheet`, "_blank")
        }
        else if (key === "shipmentLabelPrint") {
            downloadShipmentLevelList(trip, false);
        }
        else if (key === "inboundShipmentLabelPrint") {
            downloadShipmentLevelList(trip, true);
        }
    };

    const downloadShipmentLevelList = (trip, inboundTrips = false) => {
        if (tripSheetPrint.includes(trip.tripCode)) {
            notifyApiError("Trip print already in progress for - " + trip.tripCode);
            return;
        }
        SetTripSheetPrint(current => {
            var temp = [...current];
            temp.unshift(trip.tripCode);
            return temp;
        });
        downloadConsignmentsList(trip.tripId, inboundTrips)
            .then(_ => SetTripSheetPrint(current => [...current].filter(t => t !== trip.tripCode)))
            .catch(_ => SetTripSheetPrint(current => [...current].filter(t => t !== trip.tripCode)))
    }

    const actionColumn = [
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: 160,
            render: (action, trip) => <TripActionColumn trip={(!action && trip.tripStatus === "LOADED") ? { ...trip, action: "DISPATCH" } : trip} action={(!action && trip.tripStatus === "LOADED") ? "DISPATCH" : action} tripType={tripFetchType} handleMenuClick={handleMenuClick}
                onActionSuccess={_ => SetFetchTripsPayload({ ...defaultFilterPayload })} />
        }
    ]

    useEffect(_ => {
        // LoaderService.toggleTabLoaderOn()
        if (tripFetchType == "OUTBOUND_TRIP") {
            if (config?.enableRearch?.outboundTripReadOps === 'true') {
                fetchOutboundRoutesV2()
                    .then(response => {
                        SetRouteOptions(response?.routes)
                        // LoaderService.toggleTabLoaderOff()
                    })
                    .catch(e => {
                        console.error(e)
                        // LoaderService.toggleTabLoaderOff()
                    })
            } else {
                fetchOutboundRoutes()
                    .then(response => {
                        SetRouteOptions(response?.routes)
                        // LoaderService.toggleTabLoaderOff()
                    })
                    .catch(e => {
                        console.log(e)
                        // LoaderService.toggleTabLoaderOff()
                    })
            }
        }
        else {
            fetchInboundRoutes()
                .then(response => {
                    SetRouteOptions(response?.routes)
                    // LoaderService.toggleTabLoaderOff()
                })
                .catch(e => {
                    console.log(e)
                    // LoaderService.toggleTabLoaderOff()
                })
        }
    }, [])

    useEffect(_ => {
        if (fetchTripsPayload && fetchTripsPayload.tripFetchType) {
            callFetchTrips(fetchTripsPayload);
        }
    }, [fetchTripsPayload])


    return (
        <div className="white-bg border-grey spacer-m flex-column flex-gap-l align-items-stretch">
            {
                tripSheetPrint && tripSheetPrint?.length > 0 &&
                <Alert style={{ marginBottom: 25 }} showIcon={true}
                    message={<div>Shipment list print in progress for trip(s) - <strong>{tripSheetPrint?.join(", ")}</strong> </div>} />
            }
            <TripsMenu loading={loading} routeOptions={routeOptions} fetchTripsPayload={fetchTripsPayload} defaultFilterPayload={defaultFilterPayload}
                tripFetchType={tripFetchType} SetFetchTripsPayload={SetFetchTripsPayload}
                statusOptions={
                    tripFetchType == "OUTBOUND_TRIP" ?
                        ["PENDING", "DISPATCHED", "LOADED", "COMPLETED"] :
                        ["PENDING", "ARRIVED", "COMPLETED"]
                } />
            <Table loading={loading} columns={[...commonColumns, ...columns, ...actionColumn]} dataSource={tripsResponse?.trips}
                bordered={true} size={"small"} scroll={{ x: true }}
                rowKey={"tripId"}
                pagination={{
                    total: tripsResponse?.totalCount,
                    pageSize: tripsResponse?.currentPageSize,
                    current: tripsResponse?.currentPageNo,
                    onChange: onPaginationChange
                }}
            />
            {
                selectedTripCode &&
                <TripStatusModal tripCode={selectedTripCode} onCancel={_ => SetSelectedTripCode(false)} />
            }
        </div>
    )
}