import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Icon, Input, Modal } from 'antd';
import ScanActionRequiredVideoPopupModal from '../scan-action-required-video-modal';
import { notifyApiError } from '../../../common/utils/utils';

const PacketScanRequiredPopupModal = ({ open, handleClose, waybillNo, packetScanData, handelpacketScanData, isMobile }) => {
  const [packetId, setPacketId] = useState("");
  const [scanCount, setScanCount] = useState(0);
  const searchInputRef = useRef(null); 
  const changeWaybill = e => {
    setPacketId(e.target.value)
  };

  useEffect(() => {
    if (open && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100);
    }
  }, [open]);

  const scanAnyway = e => {
    handelpacketScanData({
        id: packetId,
        match: false,
        cancelAllowed: false,
        inputType: "BARCODE_SCANNER"
    })
    setPacketId("")
    setScanCount(0)
  }

  const cancelAction = e => {
    handleClose()
    setPacketId("")
    setScanCount(0)
  }

  


  const enterWaybill = e => {
    const value = packetId.toUpperCase();
    if (!value || value.trim() === "") {
        return notifyApiError("Enter waybill number to scan!")
    }

    if (packetScanData.id.toUpperCase() === value){
      handelpacketScanData({
        id: packetId,
        match: true,
        cancelAllowed: false,
        inputType: "BARCODE_SCANNER"
      })
      setPacketId("")
      setScanCount(0)
    } else {
      if (packetScanData.retryCount > (scanCount + 1)){
        setScanCount(scanCount + 1)
        setPacketId("")
      } else  {
        setScanCount(scanCount + 1)
        // handelpacketScanData({
        //   id: packetId,
        //   match: false,
        //   cancelAllowed: false,
        //   inputType: "BARCODE_SCANNER"
        // })
        // setPacketId("")
        // setScanCount(0)
      }
    }
    const scanInput = document.getElementById("scanWaybillInput");
    if (scanInput) {
        scanInput.blur();
        scanInput.setAttribute("readonly", "readonly");
    }
  }

  return (
    <>
      {packetScanData ?
       packetScanData.retryCount == scanCount ? 
       <>
         <Modal className="remove-padding" 
        title={
          <div style={{ display: 'flex', justifyContent: 'center' }}> 
            <div>Wrong Packet</div> 
          </div>
        }
        bodyStyle={{ padding: 0 }}
        style={{ padding: 0 }}
        onCancel={cancelAction} 
        visible={open}
        width={isMobile ? "100vw" : "40vw"}
        footer={
          <div style={{ display: 'flex', justifyContent: 'right' }}> 
              <Button type="primary" onClick={_ => scanAnyway()}>
                Scan anyway
              </Button>     
              <Button type="danger" onClick={_ => cancelAction()}>
                Cancel
              </Button>
          </div>
        } 
        centered={true}
        destroyOnClose={true} >
          <div>
            <div style={{display: 'flex', justifyContent: 'center', margin: 30, marginBottom: 10}}> 
              <span>
                Wrong packet scanned {packetScanData.retryCount} times for Waybil:
              </span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center',  marginBottom: 30}}> 
              <span style={{color: "#3B5A98"}}>  {waybillNo}</span>
            </div>
          </div>
        </Modal>
       
       </> :
      <>
        <Modal className="remove-padding" 
        title={
          <div style={{ display: 'flex', justifyContent: 'center' }}> 
            <div>Scan Packet ID</div> 
          </div>
        }
        onCancel={cancelAction} 
        visible={open}
        ref={searchInputRef} 
        bodyStyle={{ padding: 0 }}
        style={{ padding: 0 }}
        width={isMobile ? "100vw" : "40vw"}
        footer={
          <div style={{ display: 'flex', justifyContent: 'right' }}> 
              {scanCount > 0 ? <div style={{display: 'flex', backgroundColor: "#FEE2E4", alignItems: 'center', marginRight: 30, color: "#F22735"}}><span style={{paddingLeft: 10, paddingRight: 10}}>Wrong Packet scanned : {(packetScanData.retryCount - scanCount)} Attempt's left</span></div> : <></>}

              <Button type="danger" onClick={_ => cancelAction()}>
                Cancel
              </Button>
          </div>
        } 
        centered={true}
        destroyOnClose={true} >
          <div>
            <div style={{justifyContent: 'center', margin: 20}}> 
              <Form.Item label={<span className="form-label-text">Scan/ Enter Packet ID</span>}>
                  <Input.Search
                            placeholder={"Enter or scan Packet ID to complete scan"}
                            onChange={changeWaybill}
                            value={packetId}
                            onSearch={enterWaybill}
                            allowClear
                            autoFocus
                            addonAfter={
                                <span 
                                    style={{
                                        cursor: !packetId.length ? "no-drop" : "pointer",
                                        pointerEvents: !packetId.length ? "none" : "auto",
                                        display: "flex", 
                                        alignItems: "center",
                                        gap : 5
                                    }}
                                    onClick={enterWaybill}
                                >
                                    <Icon type="barcode" /> Scan
                                </span>
                            }
                    />                           
              </Form.Item>
              <span>AWB No:<span style={{color: "#3B5A98"}}>  {waybillNo}</span></span>
            </div>
          </div>
        </Modal>
      </> : 
      <>
      </>}
    </>
  )
}

export default PacketScanRequiredPopupModal;