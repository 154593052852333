import { apiService } from "../../../common/apiService/service";
import * as apiUtils from "../../../common/utils/apiGateway";
import { Constants } from "../../../common/utils/constants";

export const fetchPickupSparkline = (reqBody, params) => {
  return apiService.post("fm/pickup/manage/sparkline/v2", reqBody, params);
};
export const fetchfitleredLeads = data => {
  delete data.leadStatus;
  return apiService.post("fm/pickup/manage/list", data);
};
export const fetchLeadsFilterData = data => {
  return apiService.post("fm/pickup/manage/filters", { createdAt: data.createdAt });
};
export const downloadfitleredLeads = data => {
  return apiService.post("fm/pickup/manage/download", data);
};
export const fetchLeadAllocation = data => {
  return apiService.post("fm/pickup/manage/allocate", data);
};
export const fetchFEListV2 = () => {
  return apiService.get("fefmlm/riders");
}
export const fetchFElist = () => {
  return apiUtils.apiRequest({
    method: "GET",
    url: `/b2b/v1/no-cache/partners/${Constants.CURRENT_USER_ID}/locations/${Constants.userLocationId}/users?fetch_type=ASSIGNED_ASSETS_USERS`
  });
};