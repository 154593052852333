import MetabaseWidget from "../../../metabase-dashboards/components/metabase.widget";

export default function PODAuditValidation() {

    return (
        <div className="flex-column spacer-m flex-gap-l">
            <MetabaseWidget text={"Fake POD Summary"} className={"flex-1 flex-box justify-content-center"}
                reportJson={{ questionNo : 4732, reportName : "overall-fake-pod-metric", lockedParams: ["user_id"], height : "300px", dateParams: new Set(["startDate", "endDate"]) }} />
            
            <MetabaseWidget text={"Fake PODs - Hub Level View"} className={"flex-1 flex-box justify-content-center"}
                reportJson={{ questionNo : 4734, reportName : "fake-pods-hub-level", lockedParams: ["user_id"], height : "500px", dateParams: new Set(["startDate", "endDate"]) }} />

            <MetabaseWidget text={"Fake PODs - FE Level View"} className={"flex-1 flex-box justify-content-center"}
                reportJson={{ questionNo : 4735, reportName : "overall-fake-pod-metric", lockedParams: ["user_id"], height : "500px", dateParams: new Set(["startDate", "endDate"]) }} />

            <MetabaseWidget text={"Report on pending shipments"} className={"flex-1 flex-box justify-content-center"}
                reportJson={{ questionNo : 4747, reportName : "report-on-pending-shipments", lockedParams: ["user_id"], height : "500px", dateParams: new Set(["startDate", "endDate"]) }} />
        </div>
    )
}