
import { apiCustomRequest } from '../../common/apiService/service';
import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
import { notifyApiError } from '../../common/utils/utils';
export default class AuditService {

    baseURI = `/b2c/v1/audits/`;
    baseRearchURI = `hub/audit`;

    getAuditList(params) {
        const url = this.baseURI + `all`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    }

    getRearchAuditList(params) {
        const url = this.baseRearchURI + `/list`;
        return apiCustomRequest({
            url,
            method: "POST",
            data: params
        });
    }

    createAudit(params) {
        const url = this.baseURI + `create`;
        return apiRequest({
            url,
            method: "PUT",
            data: params
        });
    }

    createRearchAudit(params) {
        const url = this.baseRearchURI;
        return apiCustomRequest({
            url,
            method: "POST",
            data: params
        });
    }

    updateRearchAudit(params) {
        const url = this.baseRearchURI + `/scan`;
        return apiCustomRequest({
            url,
            method: "PUT",
            data: params
        });
    }

    updateAudit(params) {
        const url = this.baseURI + `scan`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    }

    finishAudit(params) {
        const url = this.baseURI + `finish`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    }

    finishRearchAudit(params) {
        const url = this.baseRearchURI + `/close`;
        return apiCustomRequest({
            url,
            method: "PUT",
            data: params
        });
    }

    fetchAuditRecords(params, auditId) {
        const url = this.baseURI + auditId + `/records`;
        return apiRequest({
            url,
            method: "GET",
            params
        });
    }

    fetchRearchAuditRecords(params, auditId) {
        const url = this.baseRearchURI  + `/${auditId}/records`;
        return apiCustomRequest({
            url,
            method: "GET",
            params
        });
    }

    getAuditInfo(auditId) {
        const url = this.baseURI + auditId;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    getAuditStatus(locationId) {
        const url = `/b2c/v1/audits/check/${locationId}`;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    getRearchAuditStatus(locationId) {
        const url = this.baseRearchURI + `/check/${locationId}`;
        return apiCustomRequest({
            url,
            method: "GET"
        });
    }

    checkAuditStatus(index, refreshView) {
        if (refreshView) {
            this.refreshIconService.spinIcon(0, refreshView);
        }
        this.getRearchAuditStatus(Constants.userLocationId).then((responseData) => {
            let notificationData = { 'status': false };
            if (responseData) {
                notificationData = {
                    'status': true,
                    'message': `Some of the operations are halted because ${responseData.username} initiated Audit`,
                    'auditActivatedUser': responseData.username,
                    'type': 'audit'
                };
                if (refreshView) {
                    this.refreshIconService.spinIcon(0, refreshView);
                }
            }

            if (notificationData.status) {
                notifyApiError('Audit is still in progress', 'Information');
            } else {
                this.auditObservable.refreshAuditList({ 'pageNo': 1, 'pageSize': 10, 'totalCount': 0 });
                notifyApiError('You can start all your operations', 'Information');
            }
            this.notificationBarObservable.setNotificationBar(notificationData);
        });
    }
}