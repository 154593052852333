import { ACTION_TYPES } from "./reducer"
import store from "../../utils/store"

const LoaderService = {
    toggleLoader    : () => {
        store.dispatch({ type : ACTION_TYPES.TOGGLE_LOADER })
    },
    toggleLoaderOn  : () => {
        store.dispatch({ type : ACTION_TYPES.TOGGLE_LOADER_ON })
    },
    toggleLoaderOff : () => {
        store.dispatch({ type : ACTION_TYPES.TOGGLE_LOADER_OFF })
    },
    toggleTabLoader    : () => {
        store.dispatch({ type : ACTION_TYPES.TOGGLE_TAB_LOADER })
    },
    toggleTabLoaderOn  : () => {
        store.dispatch({ type : ACTION_TYPES.TOGGLE_TAB_LOADER_ON })
    },
    toggleTabLoaderOff : () => {
        store.dispatch({ type : ACTION_TYPES.TOGGLE_TAB_LOADER_OFF })
    },
    getLoaderState : () => {
        store.getState().loaderReducer?.loader
    }
}

export default LoaderService;