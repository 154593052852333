import { Button, Dropdown, Icon, Menu, Modal, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { checkHttpStatus, notifyApiSuccess } from "../../../../common/utils/utils";
import { initiateConsigneeCall } from "../../../TrackingPopup/action";
import { fetchDRSConsignments, fetchPendingSuspiciousConsignments } from "../service";
import { DRS_SHIPMENT_STATUS, SUSPICIOUS_REASONS_MAP } from "./transform.data";
import ValidateRejectModal from "./validate.reject";
import WaybillLink from '../../../shared-components/waybill-link/index';
import moment from "moment-timezone";
import { useSelector } from "react-redux";

//used in LM Performance -> Live DRS
//used in POD -> POD Audit
export default function ConsignmentsView ({ drsId, expanded, feId, selectedCustomers }) {

    const [ consignmentsData, SetConsignmentsData ] = useState(undefined);
    const [ pageNo, SetPageNo ] = useState(1);
    const [ visible, SetVisible ] = useState(false);
    const [ validateModalType, SetValidateModalType ] = useState(false);
    const [ tagsFilterVisible, SetTagsFilterVisible ] = useState(false);
    const [ postObject, SetPostObject ] = useState({
        // drsId,
        feIds : feId ? [ feId ] : undefined,
        custIds : selectedCustomers ? selectedCustomers : undefined,
        shipmentStatus : [],
        tags   : [ "SUSPICIOUS" ]
    })
    const [ noData, SetNoData ] = useState(false);
    const [ validateWaybillDetails, SetValidateWaybillDetails ] = useState({});


    const appConfig = JSON.parse(atob(localStorage.getItem("_configrations")));
    const user = localStorage.user ? JSON.parse(atob(localStorage.user)) : undefined;
    const franchisePartner = user?.partner?.franchisePartner
    const userLocationMapping = useSelector(state => state.userLocationMappingReducer.userLocationMapping);

    const userLocationMappingExistsForUser = userLocationMapping && Object.keys(userLocationMapping)?.length > 0;
    var clusterKeys = userLocationMapping ? Object.keys(userLocationMapping) : [];
    var lsnClm = false;
    var lsnOpsAdmin = false;
    var valmoClm = false;
    var valmoOpsAdmin = false;
    const opsAdminUserIds = appConfig?.opsAdminUserIds || [];
    const valmoOpsAdminUserIds = appConfig?.valmoOpsAdminUserIds || [];
    if (valmoOpsAdminUserIds.indexOf(user?.id + "") > -1) {
        valmoOpsAdmin = true
    }
    else if (opsAdminUserIds.indexOf(user?.id + "") > -1) {
        lsnOpsAdmin = true;
    }
    if (userLocationMappingExistsForUser) {
        if (clusterKeys.length >= 1) {
            const locations = userLocationMapping[clusterKeys[0]];
            if (locations.find(l => l.source == 'LSN')) {
                lsnClm = true;
            }
            else {
                valmoClm = true;
            }
        }
    }

    const applyStatusFilter = status => {
        SetVisible(false);
        SetPostObject(current => {
            if (status === "CLEAR") {
                return {
                    ...current,
                    shipmentStatus : []
                }
            }

            var __shipmentStatus = [ ...current.shipmentStatus ];
            if (__shipmentStatus.indexOf(status) > -1) {
                __shipmentStatus = __shipmentStatus.filter(s => s != status);
            }
            else {
                __shipmentStatus.push(status);
            }
            return {
                ...current,
                shipmentStatus : __shipmentStatus
            }
        })
    }

    const applyTagsFilter = tag => {
        SetTagsFilterVisible(false);
        SetPostObject(current => {
            if (tag === "CLEAR") {
                return {
                    ...current,
                    tags : []
                }
            }

            var _tags = [ ...current.tags ];
            if (_tags.indexOf(tag) > -1) {
                _tags = _tags.filter(t => t != tag);
            }
            else {
                _tags.push(tag);
            }
            return {
                ...current,
                tags : _tags
            }
        })
    }

    const columns = [
        {
            title : "AWB No",
            dataIndex : "waybillNo",
            render : (waybillNo, waybillData) => (
                (lsnClm || lsnOpsAdmin || franchisePartner) ?
                    waybillData.status ?
                    <div className="waybill-link" 
                        onClick={_ => {
                            SetValidateWaybillDetails(waybillData);
                            SetValidateModalType(true);
                        }}>
                            {waybillNo}
                    </div> : 
                    <WaybillLink>{waybillNo}</WaybillLink> :
                <WaybillLink>{waybillNo}</WaybillLink>
            )
        },
        ...(!feId && !drsId ?
            [{
                title : "Rider Details",
                dataIndex : "feName",
                render    : (data, row) => (
                    <div className="flex-column flex-gap-xs">
                        <div className="text-semi-bold"> {data} </div>
                        <div className="light-grey-text"> {row.feNumber} </div>
                    </div>
                )
            }] : []
        ),
        ...(!feId && !drsId ?
            [{
                title : "POD Date",
                dataIndex : "podDate",
                render : data => <>{moment(data).format("DD/MM/YYYY")}</>
            }] : []
        ),
        ...(feId || drsId ? [{
            title : "Status",
            width     : 125,
            dataIndex : "status",
            render    : status => (
                <div className="flex-box">
                    {
                        <div className={`spacer-hm border-radius-m ${status ? DRS_SHIPMENT_STATUS[status]?.className : DRS_SHIPMENT_STATUS["OFD"]?.className}`}>
                            {status ? DRS_SHIPMENT_STATUS[status]?.viewText : DRS_SHIPMENT_STATUS["OFD"]?.viewText}
                        </div>
                    }
                </div>
            )
        }] : []),
        {
            title : "Undelivered Reason",
            dataIndex : "subStatus",
            render    : subStatus => subStatus || "",
            width     : 175
        },
        {
            title     : "Attempts",
            dataIndex : "attemptCount",
            width     : 80
        },
        ...(feId || drsId ? [{
            title : _ => {
                const MenuOverlay = _ =>{
                    return (
                        <Menu onClick={(e) => applyTagsFilter(e.key)}>
                            <Menu.Item key="SUSPICIOUS">
                                <div className="flex-box align-items-center flex-gap-s">
                                    {postObject.tags.indexOf("SUSPICIOUS") > -1 && <Icon type="check" style={{ color : "#1c6ce1", fontSize : 16 }} />}
                                    <div> Suspicious </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="CRITICAL">
                                <div className="flex-box align-items-center flex-gap-s">
                                    {postObject.tags.indexOf("CRITICAL") > -1 && <Icon type="check" style={{ color : "#1c6ce1", fontSize : 16 }} />}
                                    <div> Critical </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="CLEAR">
                                Clear
                            </Menu.Item>
                        </Menu>
                    )
                };

                var iconColor = "#000000D9";
                if (postObject.tags.length > 0) {
                    var iconColor = "#1c6ce1";
                }
                if (postObject.tags.length == 1) {
                    if (postObject.tags[0] == "SUSPICIOUS") {
                        iconColor = "#f44337";
                    }
                    else {
                        iconColor = "#ff9801";
                    }
                }

                return (
                    <div className="cursor-pointer flex-box align-items-center flex-gap-xs" onClick={_ => {
                        if(postObject.tags.indexOf('SUSPICIOUS') > -1) {
                            applyTagsFilter('CLEAR');
                        }
                        else {
                            applyTagsFilter('SUSPICIOUS');
                        }
                    }}>
                        <div> Tags </div>
                        <Icon type="filter" className="icon-position-overwrite"
                            style={{ fontSize : 18, color : iconColor }} />
                    </div>
                )
            },
            width     : 120,
            dataIndex : "tags",
            render    : (tags = [], waybillData) => {
                return (
                    <div className="flex-box flex-wrap flex-gap-m">
                        {
                            tags.indexOf("SUSPICIOUS") > -1 &&
                            waybillData.podStreamStatus == 'REJECTED'?
                            <div className={`spacer-hm border-radius-m error-bg error-color`}>
                                Undel Rejected
                            </div> :
                             waybillData.podStreamStatus == 'ACCEPTED' ?
                            <div className={`spacer-hm border-radius-m success-bg success-color`}>
                                Undel Accepted
                            </div> :
                            waybillData.podStreamStatus == 'UNRESOLVED'?
                            <div className={`spacer-hm border-radius-m error-bg error-color`}>
                                Suspicious
                            </div> :
                            <></>
                        }
                        {
                            tags.indexOf("CRITICAL") > -1 &&
                            <div className={`spacer-hm border-radius-m warning-bg warning-color`}>
                                Critical
                            </div>
                        }
                        { tags.length == 0 && "" }
                    </div>
                )
            }
        }] : []),
        {
            title : "Suspicious/Validated Reason",
            dataIndex : "suspiciousReason",
            render    : (suspiciousReason, waybillData) => {
                return (
                    <>
                        {
                            (!feId || (waybillData.tags && waybillData.tags.indexOf("SUSPICIOUS") > -1)) ?
                            <>
                                {
                                    waybillData.podStreamStatus == 'ACCEPTED' || waybillData.podStreamStatus == 'REJECTED' ?
                                    <div className="flex-column"> 
                                        <div className="font-size-m-1">
                                            {
                                                waybillData.podStreamStatus == 'ACCEPTED' ? "POD Accepted By" :
                                                waybillData.podStreamStatus == 'REJECTED' ? "POD Rejected By" :
                                                ""
                                            }
                                        </div>
                                        <strong> {waybillData.validatedUserName} - </strong>
                                    </div> : <></>
                                }
                                <div className="spacer-xs border-radius-m">
                                    { 
                                        waybillData.podStreamStatus == 'REJECTED' ?
                                        <div className="flex-column flex-gap-m" style={{ lineHeight : 1.2}}>
                                            <span>POD Rejected and Rider should Reattempt</span>
                                            {/* {waybillData.podStreamRemarks} <br/> */}
                                            <span>{SUSPICIOUS_REASONS_MAP[suspiciousReason] || suspiciousReason}</span>
                                        </div> :
                                        waybillData.podStreamStatus == 'ACCEPTED' ?
                                        <em> Rider actions verified and Undelivered Reason validated </em> :
                                        (SUSPICIOUS_REASONS_MAP[suspiciousReason] || suspiciousReason)
                                    }
                                </div>
                            </> : 
                            <>--</>
                        }
                    </>
                )
            }
        },
        {
            title     : "Actions",
            dataIndex : "id",
            render    : (id, waybillData) => {
                if (waybillData.status !== "DEL") {
                    return <div className="flex-column flex-gap-m" style={{ lineHeight : 1 }}>
                        {
                            waybillData.podStreamStatus == 'UNRESOLVED' ?
                                (lsnClm || lsnOpsAdmin || franchisePartner) ?
                                <Button onClick={_ => {
                                    SetValidateWaybillDetails(waybillData);
                                    SetValidateModalType(true);
                                }} className="lsn-btn-primary"> Resolve </Button> :
                                <div className="flex-box flex-gap-m flex-wrap">
                                    <Button onClick={_ => {
                                        SetValidateWaybillDetails(waybillData);
                                        SetValidateModalType("ACCEPT");
                                    }} className="lsn-btn-success"> Accept </Button>
                                    <Button onClick={_ => {
                                        SetValidateWaybillDetails(waybillData);
                                        SetValidateModalType("REJECT");
                                    }} className="lsn-btn-error"> Reattempt </Button>
                                </div> :
                            <></>
                        }
                        <Button onClick={e => {
                            const buttonElem = e.target;
                            buttonElem.disabled = true;
                            const ogInnerHTML = buttonElem.innerHTML;
                            buttonElem.innerHTML = "Call again in 30s";
                            const resetButton = () => {
                                buttonElem.disabled = false;
                                buttonElem.innerHTML = ogInnerHTML;
                            };
                            initiateConsigneeCall(waybillData?.waybillNo, "PRIMARY")
                            .then(response => {
                                if (checkHttpStatus(response?.data?.status)) {
                                    notifyApiSuccess("Call initiated successfully", waybillData?.waybillNo);
                                    setTimeout(resetButton, 30000);
                                }
                                else {
                                    resetButton();
                                }
                            })
                            .catch(e => resetButton(e));
                        }} icon="phone" className="lsn-btn-secondary">
                            Call Customer
                        </Button>
                    </div>
                }
            }
        }
    ]
    

    useEffect(() => {
        return _ => SetConsignmentsData(undefined);
    }, [])

    const callFetchDRSConsignments = _ => {
        
        if (!postObject.feIds && !postObject.drsId) {
            fetchPendingSuspiciousConsignments(pageNo)
            .then(SetConsignmentsData)
            .catch(e => {
                if (e.code === 404) {
                    SetConsignmentsData([]);
                    SetNoData(true);
                }
            })
            
        }
        else {
            fetchDRSConsignments({
                ...postObject,
                pageNo
            })
            .then(SetConsignmentsData)
            .catch(e => {
                if (e.code === 404) {
                    SetConsignmentsData([]);
                    SetNoData(true);
                }
            })
        }
    }

    useEffect(() => {
        SetNoData(false);
        if (expanded) {
            callFetchDRSConsignments();
        }
        else {
            SetConsignmentsData(undefined);
        }
    }, [ expanded, pageNo, postObject ]);

    useEffect(() => {
        if (expanded && consignmentsData !== undefined && document.querySelector(`tr[data-row-key='${drsId}']`)) {
            const offsetTop = document.querySelector(`tr[data-row-key='${drsId}']`).getBoundingClientRect().top + document.documentElement.scrollTop;
            window.scrollTo({
                top : offsetTop - 5,
                behavior : 'smooth'
            })
        }
    }, [ consignmentsData ])

    return (
        <div>
            {
                consignmentsData === undefined ?
                <Spin spinning={true} /> :
                noData ?
                <Table columns={columns} dataSource={[]}
                 locale={{
                    emptyText : "No shipments for the applied filters."
                 }}/> :
                <Table columns={columns} bordered={false} className="table-cell-bg-white"
                 dataSource={consignmentsData.response} size="middle"
                 scroll={{ x : "calc(100vw - 450px)" }}
                 pagination={{
                    total : consignmentsData?.totalCount,
                    current : consignmentsData?.currentPageNo,
                    pageSize : consignmentsData?.currentPageSize,
                    onChange : SetPageNo
                 }} />
            }
            <ValidateRejectModal validateModalType={validateModalType} SetValidateModalType={SetValidateModalType}
             onSuccess={callFetchDRSConsignments} validateWaybillDetails={validateWaybillDetails}
             enhancedResolvePopup={(lsnClm || lsnOpsAdmin || franchisePartner)} />
        </div>
    )
    
}